@import './base.css';

#app {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;

  font-weight: normal;
}

a,
.green {
  text-decoration: none;
  color: hsla(160, 100%, 37%, 1);
  transition: 0.4s;
}

@media (hover: hover) {
  a:hover {
    background-color: hsla(160, 100%, 37%, 0.2);
  }
}

@media (min-width: 1024px) {
  #app {
    height: 100%;
  }

  #app_wrapper {
    height: 100%;
  }
  #app_wrapper.layout_not_authorized{
    display: flex;
    place-items: center;
  }
  #app_wrapper.layout_authorized{
  }

  #app_content {
    padding: 0 2rem;
  }
  #app_content.layout_not_authorized{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  #app_content.layout_authorized{
    display: revert;
  }

}

@media (max-width: 600px) {
  #app {
    padding: 2px;
  }
}