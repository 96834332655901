.passport__placeholder--gradient {
  background: rgb(196, 196, 196);
  background: linear-gradient(
    327deg,
    rgba(255, 255, 255, 1) 23%,
    rgb(223, 222, 222) 50%,
    rgba(255, 255, 255, 1) 81%
  );
}

.passport__img-container {
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.passport__file-validation-message {
  height: 28px;
  opacity: 70%;
}

.passport__img {
  width: 100%;
  height: auto;
  max-height: 80%;
  object-fit: contain;
}

.passport__buttons-container {
  text-align: center;
  width: 100%;
  margin: 24px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.passport__button-group {
  display: flex;
  justify-content: space-between;
  width: 95%;
}

.passport__button {
  width: 46%;
  font-size: 0.75rem;
}
