body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.autocomp_selected li {
  display: inline-block!important;
  white-space: initial!important;
}

#htmlContainer .autocomp_selected_replaced li{
  font-weight: bold;
}

#htmlContainer .autocomp_selected_replaced ul{
  margin-left: -25px;
}

#htmlContainer{
  width: 1024px;
}

#htmlContainer .lhc-de-input-unit{
  display: inline-block!important;
  word-wrap: break-word;
/*  float: right!important;
  padding: 20px!important;*/
}

#htmlContainer .lhc-de-label-button:not(.lhc-item-group-title){
  display: inline-block!important;
  width: 40%!important;
}

#htmlContainer .lhc-form-title {
  background-color: white!important;
  text-align: center;
  color: black!important;
  font-weight: bold;
  font-size: 20px;
}

.lhc-form-body {
  border: 1px solid transparent!important;
}

* {     page-break-inside: avoid;     page-break-after: avoid;     page-break-before: avoid;   }